import {
  ADD_STUDY,
  GET_ALL_STUDIES,
  GET_STUDY_BY_ID,
  DELETE_STUDY,
  ADD_STORE_CREDENTIALS,
  ADD_USER_STORE,
  GET_USER_STORE,
} from "../Actions/Study";

const intitialState = {
  studyData: [],
  storeData: [],
  storeCredentials:[]
};

export default (state = intitialState, action) => {
  switch (action.type) {
    case ADD_STUDY:
      return {
        ...state,
      };
    case GET_ALL_STUDIES:
      return {
        ...state,
        studyData: action.payload.data,
      };
    case DELETE_STUDY:
      return {
        ...state,
        studyData: state.studyData.filter(
          (item) => item._id !== action.payload
        ),
      };
    case GET_STUDY_BY_ID:
      return {
        ...state,
        studyData: state.studyData.filter(
          (item) => item._id !== action.payload
        ),
      };
    case ADD_USER_STORE:
      return {
        ...state,
        storeData: [...state.storeData, action.payload],
      };
    case GET_USER_STORE:
      return {
        ...state,
        storeData: action.payload.data,
      };
    case ADD_STORE_CREDENTIALS:
      let storeCreds;
      if(state.storeCredentials[action.payload.data.storeId]){
        storeCreds.username = action.payload.data.credentials.username;
        storeCreds.password = action.payload.data.credentials.password;
      }else{
        storeCreds = action.payload.data.credentials
      }
      return {
        ...state,
        storeCredentials:[...state.storeCredentials,{[action.payload.data.storeId]:storeCreds}]
      }
    default:
      return state;
  }
};
