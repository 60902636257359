import React from 'react';
const Login = React.lazy(() => import('./components/Authentication/Login'));
const VerifyOtp = React.lazy(() => import('./components/Authentication/VerifyOtp'));
const ResetPassword = React.lazy(() => import('./components/Authentication/ResetPassword/ResetPassword1'));

const route = [
	{ path: '/login', exact: true, name: 'Signin', component: Login },
	{ path: '/verify-otp', exact: true, name: 'Verify OTP', component: VerifyOtp },
	{ path: '/forgot-password', exact: true, name: 'Reset Password', component: ResetPassword }
];

export default route;
