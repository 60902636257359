import { compose, applyMiddleware, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import ReduxThunk from "redux-thunk";
import { rootReducer } from "./Reducers/index";
import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "root",
  storage,
  blacklist: ['auth']
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(ReduxThunk))
);
export const persistor = persistStore(store);

export default store;
