import commonFunctions from '../helpers/commonFunctions';

const HOST = process.env.REACT_APP_API_URL;
class Api {
    static headers(multipart = false) {
        if (multipart) {
            return {
                'Content-Type': 'multipart/form-data',
            };
        } else {
            return {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            };
        }
    }

    static get(route, sendAuthToken = false) {
        return this.xhr(route, null, 'GET', sendAuthToken, false);
    }

    static put(route, params, sendAuthToken = false) {
        return this.xhr(route, params, 'PUT', sendAuthToken, false);
    }

    static post(route, params, sendAuthToken = false, multipart = false) {
        console.log('Route:::', route, 'Data:::', params);
        return this.xhr(route, params, 'POST', sendAuthToken, multipart);
    }

    static delete(route, params, sendAuthToken = false) {
        return this.xhr(route, params, 'DELETE', sendAuthToken, false);
    }

    static async logout() {
        commonFunctions.removeUser();
        window.location.href = "/";
    }

    static async xhr(route, params, verb, sendAuthToken, multipart) {
        console.log(route, params, verb, sendAuthToken, multipart);
        let token = commonFunctions.getUserToken();
        const url = `${HOST}${route}`;
        let options = Object.assign(
            {method: verb},
            params ? {body: multipart ? params : JSON.stringify(params)} : null,
        );

        options.headers = Api.headers(multipart);
        if (token && sendAuthToken) {
            options.headers.Authorization = 'Bearer ' + token;
        }
        console.log('url::', url);
        console.log('Options::', options);

        return fetch(url, options)
            .then((resp) => {
                let json = resp.json();
                // console.log("Response::", json);
                console.log('Resp::', resp.ok, resp.status);
                console.log(resp,"resp")
                if (resp.ok) {
                    return json;
                }else{
                    return json;
                }
                // return json.then((err) => {
                //     if (resp.status == 401) {
                //         Api.logout();
                //     }
                //     console.log('Err::', err);
                //     throw err;
                // });
            })
            .then((res) => {
                return res;
            });
    }
}
export default Api;
