import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import '../../node_modules/font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import Aux from '../hoc/_Aux';
import ScrollToTop from './layout/ScrollToTop';
import routes from '../route';
import commonFunctions from '../helpers/commonFunctions';
import * as adminActions from '../store/Actions/Admin';
import { withRouter } from 'react-router-dom';

const Login = React.lazy(() => import('../components/Authentication/Login'));
const AdminLayout = Loadable({
	loader: () => import('./layout/AdminLayout'),
	loading: Loader
});

class App extends Component {
	constructor(props) {
		super(props);
		this.auth = this.auth.bind(this);
		this.state = {
			isLogin: this.props.adminData
		};
	}
	auth() {
		this.setState({
			isLogin: this.props.adminData
		});
	}
	componentDidMount() {}

	render() {
		const menu = routes.map((route, index) => {
			return route.component ? (
				<Route
					key={index}
					path={route.path}
					exact={route.exact}
					name={route.name}
					render={(props) => <route.component {...props} auth={this.auth} />}
				/>
			) : null;
		});
		return (
			<Aux>
				<ScrollToTop>
					<Suspense fallback={<Loader />}>
						<Switch>
							{this.state.isLogin ? (
								<Route path="/" component={AdminLayout} />
							) : (
								[
									menu
								]
							)}
							{this.state.isLogin ? (
								<Redirect from="/" to={this.props.defaultPath} />
							) : (
								<Redirect from="/" to="/login" />
							)}
						</Switch>
					</Suspense>
				</ScrollToTop>
			</Aux>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		// didtryAutoLogin: () => dispatch(adminActions.didTryAutoLogin()),
	};
};

const mapStateToProps = (state) => {
	return {
		adminData: state.Admin.adminData,
		token: state.Admin.token,
		userData: state.Admin.userData,
		error: state.Admin.error
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
