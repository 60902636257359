const constants = {
    api_urls: {
        user_sign_in: process.env.REACT_APP_API_URL + 'users/login',
        user_sign_in_otp: process.env.REACT_APP_API_URL + 'users/verifyLogin',
        get_all_users: process.env.REACT_APP_API_URL + 'users/getAll',
        del_user: process.env.REACT_APP_API_URL + 'users/delete',
        get_all_roles: process.env.REACT_APP_API_URL + 'roles/getAll',
        add_user: process.env.REACT_APP_API_URL + 'users/add',
        add_study: process.env.REACT_APP_API_URL + 'studies/add',
        get_all_studies: process.env.REACT_APP_API_URL + 'studies/getAll',
        update_Study: process.env.REACT_APP_API_URL + 'studies/update',
        get_study_by_id: process.env.REACT_APP_API_URL + 'studies/getById',
        delete_Study: process.env.REACT_APP_API_URL + 'studies/delete',
        add_user_store: process.env.REACT_APP_API_URL + 'stores/add',
        get_user_store: process.env.REACT_APP_API_URL + 'stores/getAllByUserOrStudyId?',
        add_store_credentials: process.env.REACT_APP_API_URL + 'stores/addStoreCreds',
        update_user: process.env.REACT_APP_API_URL + 'users/update',
        get_IM_stores: process.env.REACT_APP_API_URL + 'IM/getAllStores',
        update_store: process.env.REACT_APP_API_URL + 'stores/updateStoreCreds',
        update_supportPerson: process.env.REACT_APP_API_URL + 'users/updateSupportPerson',
        get_shopping_trips: process.env.REACT_APP_API_URL + 'users/getShoppingTrips',
        get_ingredients_graphs: process.env.REACT_APP_API_URL + 'users/getIngredientsData',
        get_servings_data: process.env.REACT_APP_API_URL + 'users/getServingsData',
        get_trip_details: process.env.REACT_APP_API_URL + 'users/getTripDetails',
        get_item_details: process.env.REACT_APP_API_URL + 'users/getPurchasedItemDetails',
        get_trip_cats: process.env.REACT_APP_API_URL + 'users/getTripsCategoryView',
        get_all_cats: process.env.REACT_APP_API_URL + 'IM/getAllCategories',
        get_super_cats: process.env.REACT_APP_API_URL + 'superCategory/getAll',
        get_super_cats_for_dropdown: process.env.REACT_APP_API_URL + 'superCategory/getAll/forDropDown',
        map_categories: process.env.REACT_APP_API_URL + "superCategory/mapCategory",
        update_category_only: process.env.REACT_APP_API_URL + "IM/updateCategoryInPurchaseData",
        add_super_cats: process.env.REACT_APP_API_URL + 'superCategory/add',
        delete_super_cat: process.env.REACT_APP_API_URL + 'superCategory/delete',
        get_coach_messages: process.env.REACT_APP_API_URL + 'coachMessages/getAll',
        add_coach_message: process.env.REACT_APP_API_URL + 'coachMessages/add',
        update_coach_message: process.env.REACT_APP_API_URL + 'coachMessages/update',
        get_export_data: process.env.REACT_APP_API_URL + 'export/getExcelDataToExport',
        delete_store: process.env.REACT_APP_API_URL + 'stores/delete',

        /*NEW APIS*/
        create_participant_cluster_group_coach: 'users/add',
        add_cluster: 'clusters/add',
        add_group: 'groups/add',
        add_sub_group: 'subGroup/add',
        get_participant_cluster_group_coach: 'users/get?role=',
        get_all_clusters: 'clusters/getAll',
        get_all_groups: 'groups/getAll',
        get_all_sub_groups: 'subGroup/getAll',
        get_all_modules: 'modules/getAll',
        get_all_check_ins: 'check-ins/getAll',
        get_all_participants_in_cluster: 'users/participantsInCluster?clusterId=',
        get_all_participants_in_groups: 'users/participantsInGroup?groupId=',
        update_all_types: 'users/update',
        add_module: 'modules/add',
        delete_module: 'modules/delete',
        edit_module: 'modules/update',
        delete_participant: 'users/delete',
        update_cluster: 'clusters/update',
        update_groups: 'groups/update',
        update_sub_groups: 'subGroup/update',
        get_group_by_id: 'groups/getById?id=',
        get_user_by_id: 'users/getById',
        forget_password: 'users/forgotPassword',
        reset_password: 'users/resetPassword',
        participants_data: 'users/getInterventionAndFitBitStats?id=',
        get_participants_by_id: 'users/getParticipantsByCoachId?id=',
        get_participants_7_days_data: 'users/getLast7DaysFitBitData?id=',
        get_coach_calender: 'assignments/getCoachCalender?date=',
        get_intervention_calender: 'assignments/getInterventionCalender?date=',
        update_attendance: 'assignments/updateAssignment',
        get_stats: 'users/getGraphsDataWeb?id=',
        get_coach_bank_messages: 'coachBank/getAll',
        update_coach_bank_message: 'coachBank/update',
        delete_coach_bank_message: 'coachBank/delete',
        add_coach_bank_message: 'coachBank/add',
        get_all_config: 'config/getAll',
        update_config: 'config/update',
        connect_fitbit: 'fitbit/getRedirectUrl?id=',
        create_conversation: 'messages/getConversationByUsers',
        send_message: 'messages/createConversation',
        get_conversation: 'messages/getMessagesByMessageType?id=',
        exported_data_api: 'export/exportedData',
        disconnect_fitbit: 'fitbit/disconnectParticipantFitBit?id=',
        add_weight_loss_message: 'weightLossMessage/add',
        update_weight_loss_message: 'weightLossMessage/update',
        delete_weight_loss_message: 'weightLossMessage/delete',
        add_weight_maintenance_message: 'weightMaintenanceMessage/add',
        update_weight_maintenance_message: 'weightMaintenanceMessage/update',
        delete_weight_maintenance_message: 'weightMaintenanceMessage/delete',
        get_weight_loss_message: 'weightLossMessage/getAll',
        get_weight_maintenance_message: 'weightMaintenanceMessage/getAll',

        get_weight_probability_message: 'weightBehaviorProbability/getAll',
        delete_weight_probability_message: 'weightBehaviorProbability/delete',
        add_weight_probability_message: 'weightBehaviorProbability/add',
        update_weight_probability_message: 'weightBehaviorProbability/update',


        get_behavioral_message: 'behavioralMessage/getAll',
        delete_behavioral_message: 'behavioralMessage/delete',
        add_behavioral_message: 'behavioralMessage/add',
        update_behavioral_message: 'behavioralMessage/update',


        get_rescue_message: 'rescueMessage/getAll',
        delete_rescue_message: 'rescueMessage/delete',
        add_rescue_message: 'rescueMessage/add',
        update_rescue_message: 'rescueMessage/update',


        get_target_reinforcement_message: 'targetReinforcementStrategies/getAll',
        delete_target_reinforcement_message: 'targetReinforcementStrategies/delete',
        add_target_reinforcement_message: 'targetReinforcementStrategies/add',
        update_target_reinforcement_message: 'targetReinforcementStrategies/update',

        get_schedule_message: 'scheduleMessage/getAll',
        delete_schedule_message: 'scheduleMessage/delete',
        add_schedule_message: 'scheduleMessage/add',
        update_schedule_message: 'scheduleMessage/update',

        updateRescueMode: 'users/updateRescueMode',
        get_checkin_form_by_participant_id: 'checkIn/getByParticipantId?id=',

        update_participant_group_subgroup: 'users/updateParticipantGroupSubGroup',

        get_subgroup_message: 'subGroupMessages/getAll',
        delete_subgroup_message: 'subGroupMessages/delete',
        add_subgroup_message: 'subGroupMessages/add',
        update_subgroup_message: 'subGroupMessages/update',

        get_ff_message: 'ffMessages/getAll',
        delete_ff_message: 'ffMessages/delete',
        add_ff_message: 'ffMessages/add',
        update_ff_message: 'ffMessages/update',

        fetch_daily_fitbit_data: 'fitbit/fetchDailyFitBitDataApi',
        update_single_weekly_schedule: 'groups/updateSingleScheduleDate',
        get_meals_data: 'users/getMealsDataForDate',
        get_coach_tasks: 'users/getCoachTasks',

        update_weekly_attendance: 'users/updateAttendanceInWeekSchedule',
        get_median: 'median/get',
        get_reports: 'messages/getAccomplishmentReport',
        change_date_coach_tasks: 'users/updateCoachTaskDateTime',

        sync_last_7_days_data_of_participant: 'fitbit/syncLast7DaysFitBitData',
        send_password_to_email: 'groups/sendEmail',

        get_intervention_notes: 'users/getInterventionsForParticipant',
        update_notes: 'users/updateNotesForParticipant',

        generate_new_password: 'users/generateNewPassword',
        send_new_password: 'users/sendPasswordByEmail',
        getSuperVisionData: 'users/getSuperVisionData'
    },

    static_strings: {
        participantCantLogin: 'A Participant is not allowed to login to the admin panel',
        pleaseFillAllFields: 'Please fill all the required fields',
        success: 'Success',
        error: 'Error',
        information: 'Information',
        notFound: 'Not Found',
        sort_text: "Click on a heading to sort. Click again for descending sort.",
        resendemail: "New OTP has been sent"
    },

    local_storage_keys: {
        userData: 'USER_DATA',
        store: 'persist:root'
    },

    roles_list: {
        admin: 'ADMIN',
        coordinator: 'COORDINATOR',
        coach: 'COACH',
        participant: 'PARTICIPANT',
        ff: 'FF'
    },

    ATTENDANCE: {
        'PRESENT': 1,
        'ABSENT': 2,
        'MAKEUP': 3,
    },

    message_types: [
        {label: 'Monitoring Text', value: 'Monitoring Text'},
        {label: 'Monitoring Call', value: 'Monitoring Call'},
        {label: 'Household Call', value: 'Household Call'},
        {label: 'Benefits of Change Call', value: 'Benefits of Change Call'},
    ],
    message_statuses: [
        {label: 'Pending', value: 'Pending'},
        {label: 'Due', value: 'Due'},
        {label: 'Completed', value: 'Completed'},
        {label: 'Failed', value: 'Failed'},
    ],

    PREV_THREE_WEEK_WEIGHT_CHANGE: {
        CONSISTENT_WEIGHT_GAIN: 'Consistent weight gain',
        CONSISTENT_WEIGHT_LOSS: 'Consistent weight loss',
        MIX_OF_WEIGHT_GAINS_AND_LOSSES_OVERALL_WEIGHT_GAIN: 'Mix of weight gains and losses; overall gain',
        MIX_OF_WEIGHT_GAINS_AND_LOSSES_OVERALL_WEIGHT_LOSS: 'Mix of weight gains and losses; overall loss',
        MIX_OF_WEIGHT_GAINS_AND_LOSSES_OVERALL_WEIGHT_STABLE: 'Mix of weight gains and losses; overall stable'
    },

    PAST_WEEK_CHANGE: {
        WEIGHT_GAIN: 'Weight gain',
        WEIGHT_LOSS: 'Weight loss',
        WEIGHT_STABLE: 'Weight stable',
        WEIGHT_UNKNOWN: 'Weight is unknown'
    },

    dateFormat: 'MM/DD/YYYY',
    dateFormat2: 'MM-DD-YYYY',

    MEAL_TYPES: {
        1: 'Breakfast',
        2: 'Morning Snacks',
        3: 'Lunch',
        4: 'Afternoon Snacks',
        5: 'Dinner',
        6: 'Evening Snacks',
        7: 'Anytime',
    },

    OPERATORS: [
        {
            type: 1,
            operator: '>'
        },
        {
            type: 2,
            operator: '<'
        },
        {
            type: 3,
            operator: '>='
        },
        {
            type: 4,
            operator: '<='
        },
        {
            type: 5,
            operator: 'Range'
        },
    ],

    OPERATORS_OPTIONS: [
        {
            value: 1,
            label: '>'
        },
        {
            value: 2,
            label: '<'
        },
        {
            value: 3,
            label: '>='
        },
        {
            value: 4,
            label: '<='
        },
        {
            value: 5,
            label: 'Range'
        },
    ],

    OPERATORS_OPTIONS_BEHAVIORAL: [
        {
            value: 1,
            label: '>'
        },
        {
            value: 2,
            label: '<'
        },
        {
            value: 3,
            label: '>='
        },
        {
            value: 4,
            label: '<='
        },
        {
            value: 5,
            label: '='
        },
    ],

    PAST_WEEK_CHANGE_OPTIONS: [
        {
            value: 'weight_gain',
            label: 'Weight gain',
        },
        {
            value: 'weight_loss',
            label: 'Weight loss',
        },
        {
            value: 'weight_stable',
            label: 'Weight stable'
        }, {
            value: 'weight_change_unknown',
            label: 'Weight change is unknown'
        },
    ],

    PREV_THREE_WEEK_WEIGHT_CHANGE_OPTIONS: [
        {value: 'any', label: 'any'},
        {value: 'consistent_weight_gain', label: 'Consistent weight gain'},
        {value: 'consistent_weight_loss', label: 'Consistent weight loss'},
        {value: 'overall_gain', label: 'Mix of weight gains and losses; overall gain'},
        {value: 'overall_loss', label: 'Mix of weight gains and losses; overall loss'},
        {value: 'overall_stable', label: 'Mix of weight gains and losses; overall stable'}
    ],

    BEHAVIOR_DOMAIN_OPTIONS: [
        {value: 1, label: 'PA'},
        {value: 2, label: 'Food Tracking'},
        {value: 3, label: 'Calories'},
        {value: 4, label: 'Any'},
        {value: 5, label: 'Other'},
        {value: 6, label: 'Self Weighing'}
    ],

    CONTINGENCY_TYPE_OPTIONS: [
        {value: 1, label: 'Week'},
        {value: 2, label: 'MVPA'},
        {value: 3, label: 'No Contingency'},
        {value: 4, label: 'If app opened'},
        {value: 5, label: 'If any goal met'},
        {value: 6, label: 'If MVPA'},
    ],

    WEIGHT_CRITERION_OPTIONS_2: [
        {value: 1, label: 'PAST WEEK WEIGHT CHANGE'},
    ],

    WEIGHT_CRITERION_OPTIONS: [
        {value: 1, label: 'If [PAST WEEK WEIGHT CHANGE] <=0'},
        {value: 2, label: 'If [PAST WEEK WEIGHT CHANGE] < -0.8'},
        {value: 3, label: 'If [PAST WEEK WEIGHT CHANGE] > -0.8'},
        {value: 4, label: 'If [PAST WEEK WEIGHT CHANGE] >=0'}
    ],


    WEIGHT_CRITERION_2: {
        IF_PAST_WEEK_WEIGHT_CHANGE: 'PAST WEEK WEIGHT CHANGE',
        IF_PERCENT_PA_GOAL: 'this week PERCENT PA GOAL',
        IF_FOOD_TRACKING: 'FOOD TRACKING',
        IF_PERCENT_CALORIES: 'PERCENT CALORIES',
    },

    BEHAVIOR_CRITERION_OPTIONS: [
        {value: 1, label: 'FOOD TRACKING'},
        {value: 2, label: 'PERCENT CALORIES'},
        {value: 3, label: 'SELF WEIGH'},
        {value: 4, label: 'PERCENT PA GOAL'},
        {value: 5, label: 'MINUTES MVPA'},
        {value: 6, label: 'CALORIES'},
        {value: 7, label: 'PERCENT CALORIES WEEKLY AVERAGE'},
        {value: 8, label: 'CALORIE DAILY GOAL'},
        {value: 9, label: 'CALORIE GOAL WEEKLY'},
        {value: 10, label: 'If opened the app at least once'},
    ],

    BEHAVIOR_CRITERION_OPTIONS_2: [
        {value: 1, label: 'the average of prior 3 weeks'},
        {value: 2, label: 'increased each of past 4 weeks'},
        {value: 3, label: 'for the first time'},
        {value: 4, label: 'is highest to date'},
        {value: 5, label: 'increased each of past 4 weeks'},
        {value: 6, label: 'met for the first time'},
        {value: 7, label: 'of the previous 3 weeks'},
        {value: 8, label: 'higher than previous 3 weeks'},
        {value: 9, label: 'each week for the past for last month'},
        {value: 10, label: 'every week over past month'},
        {value: 11, label: 'minutes on any days in past week'},
        {value: 12, label: 'on all days of past week'},
        {value: 13, label: 'during past week'},
        {value: 14, label: 'the average of the prior 3 weeks'},
        {value: 15, label: 'each of the prior 3 weeks'},
        {value: 16, label: 'every week over past 4 weeks'},
    ],


    MESSAGE_TYPE_OPTIONS: [
        {value: 1, label: 'Struggling with goal'},
        {value: 2, label: 'Goal improving'},
        {value: 3, label: 'Goal declining'},
        {value: 4, label: 'Goal maintenance'},
        {value: 5, label: 'General Motivation'},
    ],

    RESCUE_MESSAGE_STATUS: {
        TURN_ON: 'The Rescue Mode has been turned on by your coach.',
        TURN_OFF: 'The Rescue Mode has been turned off by your coach.',
        REQUEST_ON: 'You have asked your coach to turn on the rescue mode.',
        REQUEST_OFF: 'You have asked your coach to turn off the rescue mode.',
    },

    MESSAGE_TYPES: {
        INTRO_MESSAGE: 'Intro Message',
        SUMMARY_MESSAGE: 'Summary Message',
        SUBGROUP_ON_MESSAGE: 'Subgroup On Message', // accomplishment
        PROMPT_MESSAGE: 'Prompt Message',
        SUBGROUP_OFF_MESSAGE: 'Subgroup Off Message',
        SUBGROUP_REPLY_MESSAGE: 'Subgroup Reply Message',

        FF_INTRO_MESSAGE: 'FF Intro Message',
        FF_SUMMARY_MESSAGE: 'FF Summary Message',
        FF_ON_MESSAGE: 'FF On Message', // accomplishment
        FF_PROMPT_MESSAGE: 'FF Prompt Message',
        FF_OFF_MESSAGE: 'FF Off Message',
        FF_REPLY_MESSAGE: 'FF Reply Message',

        COACH_MESSAGE: 'Coach Message',
        COACH_REPLY_MESSAGE: 'Coach Reply Message',

        AUTO: 'Auto', // just for participant data view
    },

    INTERVENTION_TYPE_OPTIONS: [
        {value: 1, label: 'CALL'},
        {value: 2, label: 'GROUP'},
        {value: 3, label: 'AUTOMATED MESSAGE'},
    ],

    INTERVENTION_TYPES: {
        GROUP: 'GROUP',
        CALL: 'CALL',
        MESSAGE: 'MESSAGE',
    },


    MESSAGE_BANK: [
        {
            value: 1,
            label: 'Message Bank A',
        },
        {
            value: 2,
            label: 'Message Bank B',
        },
        {
            value: 3,
            label: 'Message Bank C',
        },
        {
            value: 4,
            label: 'Message Bank D',
        },
        {
            value: 5,
            label: 'Message Bank E',
        },
        {
            value: 6,
            label: 'Special Message Bank',
        }

    ],

    MESSAGE_BANK_MAINTENANCE: [
        {
            value: 1,
            label: 'Message Bank A',
        },
        {
            value: 2,
            label: 'Message Bank B',
        },
        {
            value: 3,
            label: 'Message Bank C',
        },
        {
            value: 4,
            label: 'Message Bank D',
        },

    ],

    LAST_OPTIONS: [
        {
            value: 1,
            label: 'week prior'
        }
    ],

    SCALE: {
        string1: 'Since this time last week, I have ',
        string2: 'lbs. Since the beginning of the program, I have ',
        string3: '% of my body weight.'
    },


    CALORIES: {
        string1: 'My daily calorie goal was ',
        string2: 'cal, and based on the foods I tracked, I estimate my daily calorie average was ',
        string3: 'cal'
    },

    ACTIVITY: {
        string1: 'My total active minutes for this week was ',
        string2: 'and I ',
        string3: 'my goal of',
        string4: 'minutes',
    },


    LOG_MEALS: {
        string1: 'I tracked my intake ',
        string2: 'out of 7 days.',
    },

    EXPERIENTIAL_EXERCISE: {
        string1: 'I',
        string2: 'the experiential exercise.',
    },

    FOOD_RECORD_REFLECTION: {
        DESC: 'Spend some time reviewing your food records for the past week and answer the following questions. Be as specific as possible in your answers.',
        QUESTION1: {
            Q: 'Did you meet your calorie goal,but not lose weight? If so, where might you be missing calories?',
            EX: 'Examples: not measuring or weighing, waiting too long to track'
        },
        QUESTION2: {
            Q: 'On days you met your calorie goal, what contributes to your success?',
            EX: 'Example: I planned out my meals on Sunday, I constantly reminded myself of my long-term goals'
        },
        QUESTION3: {
            Q: 'On days you met your calorie goal, what contributes to this outcome?',
            EX: 'Example: I attended a birthday party with high-calorie food'
        },
        QUESTION4: {
            Q: 'How can yoi prevent these barriers from occurring in the future?',
            EX: 'E.g., bring my own food'
        },
        QUESTION5: {
            Q: 'Where can you cut calories in future?',
            EX: 'E.g., Through substitutions;portion control'
        },
    },

    shareOptionsValue: {
        ON: "ON",
        OFF: "OFF"
    },

    shareOptions: [
        {value: 1, label: 'ON'},
        {value: 2, label: 'OFF'}
    ],
    statusOptions: [
        {value: 'active', label: 'ACTIVE'},
        {value: 'inactive', label: 'INACTIVE'}
    ],

    coachTypeOptions: [
        {value: 'primary', label: 'PRIMARY'},
        {value: 'secondary', label: 'SECONDARY'}
    ],

    callTextCoachOptions: [
        {
            value: 1,
            label: 'PRIMARY'
        },
        {
            value: 2,
            label: 'SECONDARY'
        }
    ],

};
export default constants;
